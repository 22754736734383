<template>
  <master-layout
    v-if="ordineDiServizio"
    :showBack="false"
    smallTitle="elenco"
    bigTitle="Rilevazioni"
    titleStyle="horizontal"
    :subtitle="ordineDiServizio.titolo"
  >
    <div class="relative" style="padding-bottom: 100px; padding-top: 60px">
      <div class="hello ion-margin-bottom">
        <h5 style="text-align: center">Elenco rilevazioni servizio</h5>
        <div class="refresh-box">
          <img
            class="cursor-pointer"
            @click="getData"
            src="../../../public/img/icons/pngwing.com.png"
            @error="handleImgError"
          />
        </div>
      </div>

      <ion-row class="pb-6">
        <ion-col v-if="loadingRilevazioni">
          <ion-spinner name="crescent"></ion-spinner>
        </ion-col>

        <ion-col v-else-if="rilevazioni.length > 0">
          <div
            v-for="(rilevazione, index) in rilevazioni"
            :key="rilevazione.id"
            @click="openItemRilevazione(rilevazione)"
          >
            <ion-row>
              <ion-col size="12">
                <card-collapsable :footerVisible="rilevazione.stato == 3">
                  <template #title>
                    <div class="mb-3">
                      <span v-if="index != rilevazioni.length - 1">{{
                        new Date(rilevazione.dataOraInizio).toLocaleDateString(
                          "it",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )

                        
                      }}</span>
                      <span v-else-if="rilevazione.dataOraFine">
                        {{
                          new Date(rilevazione.dataOraFine).toLocaleDateString(
                            "it",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )


                        }}
                      </span>
                      <span v-else-if="rilevazione.dataOraInizio">{{
                        new Date(rilevazione.dataOraInizio).toLocaleDateString(
                          "it",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      }}</span>
                      
                    </div>
                    <div>
                      <span
                        v-if="index != rilevazioni.length - 1"
                        style="font-weight: bold"
                        >{{ rilevazione.luogoInizio }}</span
                      >
                      <span v-else style="font-weight: bold">
                        {{ rilevazione.luogoFine }}
                      </span>
                    </div>
                    <div v-if="rilevazione.descrizioneHistory">
                      <span>{{
                        truncate(rilevazione.descrizioneHistory[0].testo, 30)
                      }}</span>
                    </div>
                  </template>
                  <template #body>
                    <div class="item-rilevazione-dettagli">
                      <div class="elementi-multimediali">
                        <div
                          class="elemento-multimediale"
                          v-for="elemento in rilevazione.elementiMultimediali"
                          :key="elemento.id"
                        >
                          <img
                            :src="elemento.urlElemento"
                            @error="handleImgError"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="d-flex w-full justify-content-end align-items-center"
                    >
                      <button
                        @click="gotoModificaRilevazione(rilevazione)"
                        class="button-primary p-sm"
                      >
                        Modifica
                      </button>
                    </div> -->
                  </template>
                  <template #footer v-if="rilevazione.stato == 3">
                    <span>
                      {{ rilevazione.luogoInizio }}
                    </span>
                    <br />
                    <button
  
                      v-if="!rilevazione.descrizioneHistory[0].testo.includes('SERVIZIO')"
                      @click="gotoModificaRilevazione(rilevazione)"
                      class="button-primary p-sm mt-4"
                    >
                      Modifica
                    </button>
                  </template>
                </card-collapsable>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
        <ion-col v-else>
          <div>
            Ancora non ci sono rilevazioni per questa sessione. Tocca il tasto
            qui sotto per crearne una
          </div>
        </ion-col>
      </ion-row>

      <!-- <ion-row
        class="ion-justify-content-center ion-margin-top ion-padding-top"
      >
        <ion-col size="auto">
          <ion-button @click="gotoNuovaRilevazione">
            Aggiungi rilevazione</ion-button
          >
        </ion-col>
      </ion-row> -->
    </div>
    <div>
      <button
        @click="
          $router.push('/axerta/descrizione-ordine/' + $route.params.idOrdine)
        "
        class="fixed bottom left button-bottom-axerta-primary w-45 text-16"
        style="color: white"
      >
        Torna all'Ordine di Servizio
      </button>

      <button
        @click="gotoNuovaRilevazione"
        class="fixed bottom right button-bottom-axerta-secondary w-45 text-16"
      >
        Aggiungi Rilevazione
      </button>
    </div>
  </master-layout>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import MasterLayout from "../../components/MasterLayout.vue";
import PictureInput from "vue-picture-input";
// import EXIF from 'exif-js'
import ApiService from "../../common/service.api";
import { cloudUpload, refresh } from "ionicons/icons";
import {
  IonAvatar,
  IonLabel,
  IonSelectOption,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonTextarea,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  modalController,
  IonDatetime,
  //   IonRouterOutlet,
  loadingController,
  IonLoading,
  IonSpinner,
} from "@ionic/vue";
import CardCollapsable from "@/components/Nuovi/CardCollapsable.vue";

const route = useRoute();
const router = useRouter();

const loading = ref(false);

onMounted(() => {
  getData();
});

const ordineDiServizio = ref();
function getOrdineDiServizio() {
  loading.value = true;
  ApiService.get("DossierManager/GetOrdineDiServizio/" + route.params.idOrdine)
    .then((res) => {
      res.data.elementi.forEach((element) => {
        element.visible = false;
      });

      ordineDiServizio.value = res.data;
    })
    .finally(() => {
      loading.value = false;
    });
}
getOrdineDiServizio();

function handleImgError() {
  return "https://placehold.co/300x300?text=MartechRevolution";
}

function gotoNuovaRilevazione() {
  router.push({
    name: "NuovaRilevazione",
    params: {
      idOrdine: route.params.idOrdine,
      idIntervento: route.params.idIntervento,
      inizioIntervento: route.params.inizioIntervento,
      fineIntervento: route.params.fineIntervento
        ? route.params.fineIntervento
        : "in-corso",
    },
  });
}

function gotoModificaRilevazione(rilevazione) {
  localStorage.setItem(
    "axerta-currentRilevazione",
    JSON.stringify(rilevazione)
  );
  router.push({
    name: "ModificaRilevazione",
    params: {
      idOrdine: route.params.idOrdine,
      idIntervento: route.params.idIntervento,
      inizioIntervento: route.params.inizioIntervento,
      fineIntervento: route.params.fineIntervento
        ? route.params.fineIntervento
        : "in-corso",
    },
  });
}

const rilevazioni = ref([]);
const loadingRilevazioni = ref(false);
function getData() {
  loadingRilevazioni.value = true;
  ApiService.get(
    "/DossierManager/GetRilevazioniDettaglio/0/" + route.params.idIntervento
  )
    .then((res) => {
      // const data = res.data.map((x) => (x.expanded = false));
      const data = res.data;
      rilevazioni.value = data;
    })
    .finally(() => {
      loadingRilevazioni.value = false;
    });
}

function openItemRilevazione(item) {
  item.expanded = !item.expanded;
}

function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

getData();
</script>

<style lang="scss" scoped>
.item-rilevazione {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  border: 1px solid rgb(216, 216, 216);
  border-radius: 6px;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.12);
}

.item-rilevazione-dettagli {
  padding: 1rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.elementi-multimediali {
  display: flex;
  padding: 1rem 0;
}

.elemento-multimediale {
  max-width: 90px;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.hello {
  display: flex;
  justify-content: space-between;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.refresh-box {
  width: 35px;
  height: 47.59px;
  display: grid;
  padding-right: 1rem;
  place-content: center;
}
</style>
